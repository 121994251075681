import { IStory, StoryStatus } from "@/models";
import { StatusDropdown } from "@/components/atoms";

interface StoryProps {
  story: IStory;
  onStatusChange: (storyId: string, newStatus: StoryStatus) => void;
}

const StoryComponent: React.FC<StoryProps> = ({ story, onStatusChange }) => {
  return (
    <div>
      <h3>{story.title}</h3>
      <StatusDropdown story={story} onStatusChange={onStatusChange} />
    </div>
  );
};

export default StoryComponent;
