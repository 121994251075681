import React from "react";
import { useNavigate } from "react-router-dom";
import { IRoadmapMeta } from "@/models";
import { timeAgo } from "@/common/utils";

interface Props {
  roadmap: IRoadmapMeta;
}

const RoadmapTile: React.FC<Props> = ({ roadmap }) => {
  const navigate = useNavigate();
  const {
    roadmap_uuid,
    name,
    updated_at,
    target_year,
    total_initiatives,
    completed_initiatives,
  } = roadmap;

  const handleClick = () => {
    navigate(`/roadmaps/${roadmap_uuid}/overview`);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      handleClick();
    }
  };

  return (
    <div
      className="w-[352px] h-[208px] p-4 border border-gray-medium-light rounded-[18px] flex flex-col justify-between cursor-pointer"
      onClick={handleClick}
      onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
      tabIndex={0}
      role="button"
      aria-label={`Navigate to ${name} overview`}
    >
      <div className="flex items-center justify-between py-2">
        <div>
          <h2 className="font-medium text-lg">{name}</h2>
          <p className="text-base text-typography-5D">
            Last updated {timeAgo(updated_at)}
          </p>
        </div>

        <div className="w-[77px] h-[77px] rounded-full bg-gradient flex items-center justify-center">
          <div className="w-[75px] h-[75px] rounded-full bg-white flex items-center justify-center">
            <span style={{ fontFamily: "Share Tech Mono", fontSize: "28px" }}>
              {target_year}
            </span>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-around py-4">
        <div className="flex flex-col items-center">
          <h3 className="font-medium text-[22px]">{total_initiatives}</h3>
          <p className="text-sm text-typography-58">
            Initiative{total_initiatives > 1 ? "s" : ""}
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h3 className="font-medium text-[22px]">{completed_initiatives}</h3>
          <p className="text-sm text-typography-58">Completed</p>
        </div>
      </div>
    </div>
  );
};

export default RoadmapTile;
