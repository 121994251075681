import ApiService from "../api.base";
import MilestoneEndpoints from "../endpoints/milestone.endpoint";
import { StoryStatus } from "@/models/story";

export class StoryService extends ApiService {
  static async updateStoryStatus(
    storyId: string,
    newStatus: StoryStatus
  ): Promise<void> {
    return this.put(MilestoneEndpoints.UPDATE_STORY_STATUS(storyId), {
      status: newStatus,
    });
  }
}
