import ApiService from "../api.base";
import UserEndpoints from "../endpoints/user.endpoints";

export class UserService extends ApiService {
  static async me(): Promise<any> {
    return this.get(UserEndpoints.ME);
  }

  static async subscribeToEpicsMailingList(): Promise<any> {
    return this.patch(UserEndpoints.EPICS_MAILING_LIST_SUBSCRIPTION);
  }
}
