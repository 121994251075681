import { IQuarter } from "./quarter";

export enum RoadmapStatus {
  ON_TRACK = "On Track",
  DELAYED = "Delayed",
  AHEAD = "Ahead",
}

export interface INewRoadmap {
  roadmap_id: string;
  roadmap_uuid: string;
}

export interface IRoadmapMeta {
  roadmap_id: number;
  roadmap_uuid: string;
  name: string;
  target_year: number;
  created_at: string;
  updated_at: string;
  user_id: number;
  total_initiatives: number;
  completed_initiatives: number;
}

export interface IRoadmap {
  roadmap_id: number;
  roadmap_uuid: string;
  name: string;
  target_year: number;
  created_at: string;
  updated_at: string;
  user_id: number;
  quarters: IQuarter[];

  statistics: {
    initiatives: number;
    completed: number;
    canceled: number;
    on_track: number;
    scheduled: number;
    at_rist: number;
  };
}
