import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

interface BreadcrumbItem {
  name: string;
  to?: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="mb-4 flex items-center gap-2">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <li className={classNames("text-[17px]")}>
              {item.to ? (
                <Link to={item.to} className="text-dark-blue border-b border-dark-blue">
                  {item.name}
                </Link>
              ) : (
                <span className="text-typography-92">{item.name}</span>
              )}
            </li>
            {index < items.length - 1 && <img src="/icons/slash.svg" alt="" />}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
