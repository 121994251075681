import classNames from "classnames";
import React, { useState, useRef, useEffect, ReactNode } from "react";

interface DropdownProps {
  button: ReactNode;
  position?: "right" | "left" | "center";
  children: ReactNode;
}

export const Dropdown: React.FC<DropdownProps> = ({
  button,
  position = "center",
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = (e: any) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev); // Functional update for reliable state toggle
  };

  const closeDropdown = (e: any) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  // Close dropdown when clicking outside or pressing "Escape"
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      {/* Dropdown Button */}
      <div
        onClick={toggleDropdown}
        onKeyDown={toggleDropdown}
        role="button"
        tabIndex={0}
      >
        {button}
      </div>

      {/* Dropdown Menu */}
      {isOpen && (
        <div
          className={classNames("absolute z-10", {
            "left-1/2 -translate-x-1/2": position === "center",
            "right-0": position === "right",
            "left-0": position === "left",
          })}
          onClick={closeDropdown}
        >
          {children}
        </div>
      )}
    </div>
  );
};
