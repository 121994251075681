import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useParams, Navigate } from "react-router-dom";
import { IEpic } from "@/models";
import { EpicService } from "@/api/services";
import EpicSettings from "./EpicSettings";
import Metrics from "./Metrics";
import Overview from "./Overview";

const EpicDetails: React.FC = () => {
  const { epicUuid } = useParams<{ epicUuid: string }>();
  const [epic, setEpic] = useState<IEpic | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getEpic = async () => {
      try {
        if (epicUuid === undefined) {
          setLoading(false);
          setError("Epic not found");
          return;
        }

        const epic: IEpic = await EpicService.getEpic(epicUuid);
        setEpic(epic);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching epic:", error);
        setError("Failed to fetch epic");
        setLoading(false);
      }
    };

    getEpic();
  }, [epicUuid]);

  if (epicUuid === undefined) {
    return <h3>This epic doesn't exist, a small glitch in the matrix.</h3>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (epic === undefined) {
    return <div>No epic found!</div>;
  }

  return (
    <div style={{ display: "flex" }}>
      <nav style={{ width: "200px", padding: "20px", background: "#f0f0f0" }}>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          <li>
            <Link to={`/epics/${epicUuid}/overview`}>Overview</Link>
          </li>
          <li>
            <Link to={`/epics/${epicUuid}/metrics`}>Metrics</Link>
          </li>
          <li>
            <Link to={`/epics/${epicUuid}/settings`}>Epic Settings</Link>
          </li>
          <li>
            <Link to={`/epics`}>All Epics</Link>
          </li>
        </ul>
      </nav>
      <div style={{ marginLeft: "20px", padding: "20px", flex: 1 }}>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={`/epics/${epicUuid}/overview`} />}
          />
          <Route
            path="overview"
            element={<Overview epicUuid={epicUuid} epicId={epic.epic_id} />}
          />
          <Route path="metrics" element={<Metrics epicId={epic.epic_id} />} />
          <Route path="settings" element={<EpicSettings />} />
          <Route
            path="*"
            element={<Navigate to={`/epics/${epicUuid}/overview`} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default EpicDetails;
