import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { IRoadmap, QuarterType } from "@/models";
import { RoadmapService } from "@/api/services";
import { addQuarterStatus } from "@/common/utils";

interface RoadmapContextProps {
  roadmapUuid: string;
  roadmap: IRoadmap | null;
  error: string | null;
  loading: boolean;
  quarterSelectOptions: Array<{ label: QuarterType; value: number }>;
  setRoadmap: (roadmap: IRoadmap | null) => void;
  reloadRoadmap: () => void;
}

const RoadmapContext = createContext<RoadmapContextProps | undefined>(
  undefined
);

export const RoadmapProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { roadmapUuid } = useParams<{ roadmapUuid: string }>();
  const [roadmap, setRoadmap] = useState<IRoadmap | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const quarterSelectOptions = useMemo(
    () =>
      roadmap?.quarters?.map((quarter) => ({
        label: quarter.name,
        value: quarter.quarter_id,
      })) || [],
    [roadmap?.quarters]
  );

  const fetchRoadmap = useCallback(async () => {
    setLoading(true);
    try {
      if (roadmapUuid) {
        const fetchedRoadmap = await RoadmapService.fetchRoadmap(roadmapUuid);
        setRoadmap(addQuarterStatus(fetchedRoadmap));
      }
    } catch (error) {
      console.error("Failed to fetch roadmap:", error);
      setError("Failed to fetch roadmap");
    }
    setLoading(false);
  }, [roadmapUuid]);

  useEffect(() => {
    (async () => {
      await fetchRoadmap();
    })();
  }, [fetchRoadmap]);

  return (
    <RoadmapContext.Provider
      value={{
        roadmapUuid: roadmapUuid || "",
        roadmap,
        error,
        loading,
        quarterSelectOptions,
        setRoadmap,
        reloadRoadmap: fetchRoadmap,
      }}
    >
      {children}
    </RoadmapContext.Provider>
  );
};

export const useRoadmap = () => {
  const context = useContext(RoadmapContext);
  if (!context) {
    throw new Error("useRoadmap must be used within an RoadmapProvider");
  }
  return context;
};
