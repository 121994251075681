import React from "react";
import { IStory, StoryStatus } from "@/models";

interface StatusDropdownProps {
  story: IStory;
  onStatusChange: (storyId: string, newStatus: StoryStatus) => void;
}

export const StatusDropdown: React.FC<StatusDropdownProps> = ({
  story,
  onStatusChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onStatusChange(story.story_id, event.target.value as StoryStatus);
  };

  return (
    <select value={story.status} onChange={handleChange}>
      <option value={StoryStatus.TODO}>Todo</option>
      <option value={StoryStatus.IN_PROGRESS}>In Progress</option>
      <option value={StoryStatus.DONE}>Done</option>
    </select>
  );
};
