import React, { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";

interface NavItemProps {
  to: string;
  label: string;
  iconName: string;
  isActive: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ to, label, iconName, isActive }) => {
  const getIconSrc = (isActive: boolean, iconName: string) =>
    `/icons/${iconName}.${isActive ? "white" : "blue"}.svg`;

  return (
    <li className="py-1" role="none">
      <NavLink
        to={to}
        className={classNames(
          "p-2 flex items-center gap-2 font-medium rounded-lg transition-colors duration-150",
          isActive ? "text-white bg-blue" : "text-dark-blue bg-transparent"
        )}
        aria-current={isActive ? "page" : undefined}
      >
        <img
          src={getIconSrc(isActive, iconName)}
          alt={`${label} icon`}
          className="w-7 h-7"
        />
        <span>{label}</span>
      </NavLink>
    </li>
  );
};

export const Navigation: React.FC = () => {
  const location = useLocation();

  // Helper function to determine if a nav item is active
  const isNavItemActive = (navItemPath: string) => {
    const currentPathSegment = location.pathname.split("/")[1];
    const navItemSegment = navItemPath.split("/")[1];
    return currentPathSegment === navItemSegment;
  };

  const navItems = useMemo(
    () => [
      { to: "/epics", label: "Epics", iconName: "house" },
      { to: "/roadmaps", label: "Roadmaps", iconName: "roadmap" },
    ],
    []
  );

  return (
    <nav
      className="w-[234px] px-2 pt-32"
      role="navigation"
      aria-label="Main Navigation"
    >
      <ul>
        {navItems.map((item) => (
          <NavItem
            key={item.to}
            to={item.to}
            label={item.label}
            iconName={item.iconName}
            isActive={isNavItemActive(item.to)}
          />
        ))}
      </ul>
    </nav>
  );
};
