import ApiService from "../api.base";
import MetricsEndpoints from "../endpoints/metrics.endpoint";
import { IEpicSummary } from "@/models";

export class MetricsService extends ApiService {
  static async getStoriesSummary(epicId: number): Promise<IEpicSummary> {
    return this.get(MetricsEndpoints.STORY_SUMMARY(epicId));
  }
}

