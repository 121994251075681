import React from "react";
import { UserService } from "@/api/services";
import { Button } from "@/components/atoms";
import { useAuth } from "@/contexts/auth.context";

const UnderConstruction: React.FC = () => {
  const { user, setUser } = useAuth();

  const handleClick = async () => {
    try {
      const updatedUser = await UserService.subscribeToEpicsMailingList();
      setUser(updatedUser);
    } catch (error) {
      console.error("Unable to subscribe:", error);
      alert("There was an issue subscribing you to the mailing list. Please try again later.");
    }
  };

  const isSubscribed = user?.subscribed_to_epics_mailing_list;

  return (
    <div role="main" className="flex flex-col items-center">
      <header className="flex items-center justify-between w-full">
        <h2 className="font-medium text-2xl">Coming soon 🚧</h2>
      </header>

      <main className="w-full py-10 flex flex-col items-center gap-4">
        <img
          src="/images/under-construction.png"
          alt="Under Construction"
          className="w-[400px]"
        />
        <h4 className="max-w-[276px] font-semibold text-lg leading-6 text-center">
          Break Down Your Projects with Milestones and Tasks
        </h4>
        <p className="max-w-[414px] text-typography-58 text-lg leading-6 text-center">
          Easily break down your projects into milestones and tasks, track progress, and stay on schedule.
        </p>
        <Button
          className="w-[406px] mt-8"
          disabled={isSubscribed}
          onClick={handleClick}
        >
          {isSubscribed ? "Subscribed" : "Notify on release"}
        </Button>
      </main>
    </div>
  );
};

export default UnderConstruction;