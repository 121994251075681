import React, { useState } from "react";
import { getQuarterInfo } from "@/common/utils";
import { IInitiative, IQuarter, QuarterStatus } from "@/models";
import classNames from "classnames";
import Initiative from "./Initiative";
import {
  CreateInitiativeButton,
  CreateInitiativeTile,
} from "./CreateInitiativeButtons";
import InitiativeFormModal, { OpenSourceType } from "./InitiativeFormModal";

interface QuarterProps {
  quarter: IQuarter;
}

const Quarter: React.FC<QuarterProps> = ({ quarter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSource, setOpenSource] = useState<OpenSourceType>();
  const [selectedInitiative, setSelectedInitiative] = useState<IInitiative>();

  const { daysLeftInQuarter } = getQuarterInfo();

  return (
    <div className="flex gap-12">
      <div
        className={classNames(
          "w-[104px] min-h-[365px] rounded-lg border flex flex-col items-center justify-center gap-2",
          quarter.status === QuarterStatus.PAST && "text-green border-green",
          quarter.status === QuarterStatus.PRESENT &&
            "text-white border-blue bg-blue",
          quarter.status === QuarterStatus.FUTURE && "text-blue border-blue"
        )}
      >
        <h2 className="font-bold text-2xl">{quarter.name}</h2>
        {quarter.status === QuarterStatus.PRESENT && (
          <p className="font-semibold text-sm opacity-75">
            {daysLeftInQuarter} days left
          </p>
        )}
        {quarter.initiatives.length > 0 && (
          <CreateInitiativeButton
            className={classNames(
              quarter.status === QuarterStatus.PAST && "bg-green",
              quarter.status === QuarterStatus.PRESENT && "bg-blue-light",
              quarter.status === QuarterStatus.FUTURE && "bg-blue"
            )}
            onClick={() => {
              setSelectedInitiative(undefined);
              setOpenSource(OpenSourceType.FROM_QUARTER_BAR);
              setIsOpen(true);
            }}
          />
        )}
      </div>
      <div className="h-fit flex-1 flex flex-wrap gap-4">
        {quarter.initiatives.length ? (
          quarter.initiatives.map((initiative) => (
            <Initiative
              quarter={quarter}
              initiative={initiative}
              key={`initiative-${initiative.initiative_id}`}
              onClick={() => {
                setSelectedInitiative(initiative);
                setOpenSource(OpenSourceType.FROM_INTIATIVE_ITEM);
                setIsOpen(true);
              }}
            />
          ))
        ) : (
          <CreateInitiativeTile
            onClick={() => {
              setSelectedInitiative(undefined);
              setOpenSource(OpenSourceType.FROM_EMPTY_SCREEN);
              setIsOpen(true);
            }}
          />
        )}
      </div>
      {isOpen && (
        <InitiativeFormModal
          quarter={quarter}
          initiative={{
            ...selectedInitiative,
            target_date: selectedInitiative?.target_date?.split("T")?.[0],
          }}
          openSource={openSource}
          isOpen={isOpen}
          onClose={() => {
            setSelectedInitiative(undefined);
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default Quarter;
