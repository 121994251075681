import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "@/contexts/auth.context";
import { Button, LoadingSpinner, TextInput } from "@/components/atoms";
import { useSearchParams } from "react-router-dom";

type RegisterFormInputType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const Register: React.FC = () => {
  const { register: createAccount } = useAuth();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<RegisterFormInputType>({
    defaultValues: {
      email: searchParams.get("email") || "",
    },
  });

  const onSubmit = async (data: RegisterFormInputType) => {
    const { firstName, lastName, email, password } = data;
    setError(null);
    setLoading(true);
    try {
      await createAccount(email, password, firstName, lastName);
    } catch (error: any) {
      setError(error.response?.data?.error || "Registeration Failed.");
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <img src="/logo-circular.png" alt="Logo" className="w-16 h-16 mb-12" />
      <h1 className="text-xl font-bold leading-tight tracking-tight text-typography-0C md:text-2xl">
        Plan. Execute. Succeed.
      </h1>
      <p className="text-lg font-normal leading-tight tracking-tight text-typography-5D md:text-xl">
        Create your Topigon account
      </p>

      <form
        className="space-y-4 md:space-y-6 w-full md:w-96 mt-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextInput
          label="First name"
          type="text"
          {...register("firstName", {
            required: "First name is required",
          })}
          error={!!errors.firstName}
          errorMessage={errors.firstName?.message as string}
          disabled={loading}
        />
        <TextInput
          label="Last name"
          type="text"
          {...register("lastName", {
            required: "Last name is required",
          })}
          error={!!errors.lastName}
          errorMessage={errors.lastName?.message as string}
          disabled={loading}
        />
        <div className="w-full border-b pt-3" />
        <div>
          <TextInput
            label="Email address"
            type="email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Enter a valid email address",
              },
            })}
            error={!!errors.email || error === "DUPLICATED_EMAIL"}
            errorMessage={errors.email?.message as string}
            onChangeCapture={() => setError(null)}
          />
          {error === "DUPLICATED_EMAIL" && (
            <p className="text-sm font-normal text-error mt-2">
              Account already registered.{" "}
              <a href="/login" className="font-bold text-blue underline">
                Log In
              </a>
            </p>
          )}
        </div>
        <TextInput
          label="Password"
          type="password"
          {...register("password", {
            required: "Password is required",
          })}
          error={!!errors.password}
          errorMessage={errors.password?.message as string}
          disabled={loading}
        />

        {error && error !== "DUPLICATED_EMAIL" && (
          <p className="text-sm font-normal text-error mt-2">{error}</p>
        )}

        <div>
          <Button
            type="submit"
            className="w-full"
            disabled={loading || !isValid}
          >
            {loading ? (
              <div className="flex items-center justify-center gap-4">
                <LoadingSpinner size={24} />
                <span>Creating account...</span>
              </div>
            ) : (
              "Create account"
            )}
          </Button>

          <p className="text-sm font-normal text-typography-6E mt-4">
            Already have an account?{" "}
            <a href="/login" className="font-bold text-blue hover:underline">
              Log In
            </a>
          </p>

          <p className="text-sm font-normal text-typography-6E mt-4 max-w-[350px]">
            By registering, you acknowledge that you understand and agree to the{" "}
            <a href="https://topigon.com/terms-and-conditions" className="font-bold">
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a href="https://topigon.com/privacy-policy" className="font-bold">
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </form>
    </div>
  );
};

export default Register;
