import React, { useMemo } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useAuth } from "@/contexts/auth.context";
import { Dropdown, Navigation } from "@/components/atoms";

const Layout: React.FC = () => {
  const { user, logout } = useAuth();

  // Memoized extraction of user's first initial
  const userInitial = useMemo(
    () => user?.email?.charAt(0).toUpperCase(),
    [user]
  );

  return (
    <div className="flex w-screen h-screen">
      {/* Navigation Sidebar */}
      <Navigation />

      {/* Main Content Area */}
      <main className="flex-1 flex flex-col">
        {/* Header Section */}
        <header className="h-[114px] w-full px-4 md:px-11 flex items-center justify-between">
          <NavLink to="/">
            <img
              src="/logo.png"
              alt="Logo"
              className="w-12 h-12 md:w-16 md:h-16"
            />
          </NavLink>

          {/* User Dropdown */}
          {user?.email && (
            <Dropdown
              position="right"
              button={
                <div
                  className="w-7 h-7 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-purple text-white cursor-pointer"
                  aria-label="User menu"
                >
                  {userInitial}
                </div>
              }
            >
              <div className="w-max min-w-[180px] mt-2 py-2 border rounded-lg bg-white shadow-xl flex flex-col gap-2">
                <button
                  className="px-4 py-2 hover:bg-gray-light flex items-center gap-4 cursor-pointer"
                  onClick={logout}
                >
                  <img
                    className="w-5 h-5"
                    src="/icons/arrowshape.turn.up.left.circle.fill.svg"
                    alt="Logout icon"
                  />
                  <p className="text-lg">Sign out</p>
                </button>
              </div>
            </Dropdown>
          )}
        </header>

        {/* Main Content */}
        <section className="flex-1 px-4 md:px-14 py-4 overflow-auto">
          <Outlet />
        </section>
      </main>
    </div>
  );
};

export default Layout;
