import { forwardRef, TextareaHTMLAttributes } from "react";
import classNames from "classnames";

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  label: string;
  error?: boolean;
  errorMessage?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  ({ className = "", label, error, errorMessage = "", ...rest }, ref) => {
    return (
      <div className={className}>
        <label className="block mb-2 text-sm font-medium text-typography-4D">
          {label}
        </label>
        <textarea
          ref={ref}
          className={classNames(
            "bg-gray-50 border text-typography-0C rounded-lg block w-full p-2.5",
            error ? "border-error" : "border-gray-300"
          )}
          {...rest}
        />
        {errorMessage && <p className="text-error mt-1">{errorMessage}</p>}
      </div>
    );
  }
);
