import { Button } from "@/components/atoms";
import classNames from "classnames";
import React from "react";

interface CreateInitiativeButtonProps {
  className?: string;
  onClick?: () => void;
}

export const CreateInitiativeButton: React.FC<CreateInitiativeButtonProps> = ({
  className,
  onClick,
}) => {
  return (
    <button
      className={classNames(
        "w-[42px] h-[42px] rounded-full flex items-center justify-center",
        className
      )}
      onClick={onClick}
    >
      <img
        src="/icons/plus.circle.fill.svg"
        alt="create new"
        className="w-[20px] h-[20px]"
      />
    </button>
  );
};

export const CreateInitiativeTile: React.FC<CreateInitiativeButtonProps> = ({
  onClick,
}) => {
  return (
    <div className="w-full h-[365px] bg-gray bg-opacity-5 border-dashed border-2 border-gray rounded-lg flex flex-col items-center justify-center gap-4">
      <img src="/icons/lamp.blue.svg" alt="create new" className="w-73 h-73" />

      <p className="text-lg font-medium">No initiatives...yet</p>

      <p className="max-w-64 text-base text-typography-6E text-center">
        Organize, track, and adjust your feature plans for each quarter.
      </p>

      <Button className="px-3 font-normal" onClick={onClick}>
        <img
          src="/icons/plus.circle.fill.svg"
          alt="create new"
          className="w-6 h-6"
        />
        New Initiative
      </Button>
    </div>
  );
};
