import React, { useEffect, useState } from "react";
import { RoadmapService } from "@/api/services";
import { Button, LoadingSpinner } from "@/components/atoms";
import { IRoadmapMeta } from "@/models";
import RoadmapTile from "./RoadmapTile";
import RoadmapFormModal from "./RoadmapFormModal";

const Roadmaps: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roadmaps, setRoadmaps] = useState<IRoadmapMeta[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const toggleModal = () => setIsOpen(!isOpen);

  useEffect(() => {
    const getRoadmaps = async () => {
      setLoading(true);
      setErrorMessage(null); // Reset error message before fetching
      try {
        const fetchedRoadmaps = await RoadmapService.fetchAllRoadmaps();
        setRoadmaps(fetchedRoadmaps);
      } catch (error) {
        setErrorMessage(`Failed to get all roadmaps: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    getRoadmaps();
  }, []);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center">
          <LoadingSpinner />
        </div>
      );
    }

    if (errorMessage) {
      return (
        <div className="flex justify-center">
          <p className="text-error">{errorMessage}</p>
        </div>
      );
    }

    if (roadmaps.length > 0) {
      return (
        <div className="w-full py-8 flex flex-wrap gap-4">
          {roadmaps.map((roadmap) => (
            <RoadmapTile key={roadmap.roadmap_id} roadmap={roadmap} />
          ))}
        </div>
      );
    }

    return <NoRoadmaps onCreateClick={toggleModal} />;
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="font-medium text-2xl">Recents</h2>
        {roadmaps.length > 0 && <CreateButton handleClick={toggleModal} />}
      </div>
      {renderContent()}
      <RoadmapFormModal isOpen={isOpen} onClose={toggleModal} />
    </div>
  );
};

const CreateButton: React.FC<{ handleClick: () => void }> = ({
  handleClick,
}) => (
  <Button className="px-3" onClick={handleClick}>
    <img
      src="/icons/plus.circle.fill.svg"
      alt="create new"
      className="w-6 h-6"
    />
    New Roadmap
  </Button>
);

const NoRoadmaps: React.FC<{ onCreateClick: () => void }> = ({
  onCreateClick,
}) => (
  <div className="w-full py-8 flex justify-center">
    <div className="w-[700px] h-[125px] px-8 rounded-lg bg-primary bg-opacity-15 flex items-center gap-6">
      <img
        src="/icons/roadmap-in-blue-round.svg"
        alt="roadmap"
        className="w-[62px] h-[62px]"
      />
      <div>
        <h1 className="font-bold text-[22px]">Create roadmaps</h1>
        <p className="text-base text-typography-65">
          Plan ahead. Stay on course.
        </p>
      </div>
      <div className="flex-1" />
      <CreateButton handleClick={onCreateClick} />
    </div>
  </div>
);

export default Roadmaps;
