import React from "react";
import RoadmapOverview from "./RoadmapOverview";
import { RoadmapProvider } from "@/contexts/roadmap.context";

const Screen: React.FC = () => {
  return (
    <RoadmapProvider>
      <RoadmapOverview />
    </RoadmapProvider>
  );
};

export default Screen;
