import Cookies from 'js-cookie';
import { CookieKeys } from '@/common/consts';

export const removeRefreshToken = () => {
  Cookies.remove(CookieKeys.refreshToken);
}

export const getRefreshToken = () => {
  return Cookies.get(CookieKeys.refreshToken);
}
