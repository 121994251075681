// apiService.ts
import axiosInstance from "./axios.instance";
import axios, { AxiosResponse } from "axios";

type RequestHeaders = { [key: string]: string };
type Data = { [key: string]: any };

enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

class ApiService {
  static async request(
    endpoint: string,
    method: string,
    data: Data = {},
    additionalHeaders: RequestHeaders = {},
    queryParams: Data = {}
  ): Promise<any> {
    const headers: RequestHeaders = {
      ...(axiosInstance.defaults.headers.common as RequestHeaders),
      ...additionalHeaders,
    };

    try {
      let response: AxiosResponse<any> = {} as AxiosResponse<any>;
      switch (method) {
        case HttpMethod.GET:
          response = await axiosInstance.get(endpoint, {
            headers,
            params: queryParams,
          });
          break;
        case HttpMethod.POST:
          response = await axiosInstance.post(endpoint, data, {
            headers,
            params: queryParams,
          });
          break;
        case HttpMethod.DELETE:
          response = await axiosInstance.delete(endpoint, {
            headers,
            params: queryParams,
          });
          break;
        case HttpMethod.PATCH:
          response = await axiosInstance.patch(endpoint, data, {
            headers,
            params: queryParams,
          });
          break;
        case HttpMethod.PUT:
          response = await axiosInstance.put(endpoint, data, {
            headers,
            params: queryParams,
          });
          break;
      }

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error(
          "HTTP error!",
          error.response.status,
          error.response.data
        );
      } else {
        console.error("Fetch error:", error);
      }

      throw error;
    }
  }

  static async get(
    endpoint: string,
    queryParams: Data = {},
    additionalHeaders: RequestHeaders = {}
  ): Promise<any> {
    return this.request(
      endpoint,
      HttpMethod.GET,
      {},
      additionalHeaders,
      queryParams
    );
  }

  static async post(
    endpoint: string,
    data: Data = {},
    queryParams: Data = {},
    additionalHeaders: RequestHeaders = {}
  ): Promise<any> {
    return this.request(
      endpoint,
      HttpMethod.POST,
      data,
      additionalHeaders,
      queryParams
    );
  }

  static async put(
    endpoint: string,
    data: Data = {},
    queryParams: Data = {},
    additionalHeaders: RequestHeaders = {}
  ): Promise<any> {
    return this.request(
      endpoint,
      HttpMethod.PUT,
      data,
      additionalHeaders,
      queryParams
    );
  }

  static async patch(
    endpoint: string,
    data: Data = {},
    queryParams: Data = {},
    additionalHeaders: RequestHeaders = {}
  ): Promise<any> {
    return this.request(
      endpoint,
      HttpMethod.PATCH,
      data,
      additionalHeaders,
      queryParams
    );
  }

  static async delete(
    endpoint: string,
    data: Data = {},
    queryParams: Data = {},
    additionalHeaders: RequestHeaders = {}
  ): Promise<any> {
    return this.request(
      endpoint,
      HttpMethod.DELETE,
      data,
      additionalHeaders,
      queryParams
    );
  }
}

export default ApiService;
