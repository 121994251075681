import { format } from "date-fns";
import {
  IInitiative,
  InitiativeStatus,
  IRoadmap,
  QuarterStatus,
  QuarterType,
  RoadmapStatus,
} from "@/models";
import { getQuarterDates, getQuarterInfo } from "./date";

export const getAllInitiativesFromRoadmap = (
  roadmap: IRoadmap
): IInitiative[] => {
  return roadmap.quarters.flatMap((quarter) => quarter.initiatives);
};

export const countCompletedInitiatives = (roadmap: IRoadmap): number => {
  const allInitiatives = getAllInitiativesFromRoadmap(roadmap);

  return allInitiatives.filter(
    (initiative) => initiative.status === InitiativeStatus.COMPLETED
  ).length;
};

export const countExpectedCompleteInitiatives = (roadmap: IRoadmap): number => {
  const allInitiatives = getAllInitiativesFromRoadmap(roadmap);

  return allInitiatives.filter(
    (initiative) =>
      initiative.status !== InitiativeStatus.CANCELED &&
      new Date(initiative.target_date) < new Date()
  ).length;
};

export const countActiveInitiatives = (roadmap: IRoadmap): number => {
  const allInitiatives = getAllInitiativesFromRoadmap(roadmap);

  return allInitiatives.filter(
    (initiative) => initiative.status !== InitiativeStatus.CANCELED
  ).length;
};

export const calculateRoadmapProgress = (
  roadmap: IRoadmap
): {
  actualProgress: number;
  expectedProgress: number;
  status: RoadmapStatus;
} => {
  const activeInitiatives = countActiveInitiatives(roadmap);
  const completedInitiatives = countCompletedInitiatives(roadmap);
  const expectedCompleteInitiatives = countExpectedCompleteInitiatives(roadmap);

  // Calculate where the progress should be
  const expectedProgress =
    (expectedCompleteInitiatives / activeInitiatives) * 100;

  // Compare actual to expected progress
  const actualProgress = (completedInitiatives / activeInitiatives) * 100;

  let status: RoadmapStatus;
  if (completedInitiatives < expectedCompleteInitiatives) {
    status = RoadmapStatus.DELAYED;
  } else if (completedInitiatives === expectedCompleteInitiatives) {
    status = RoadmapStatus.ON_TRACK;
  } else {
    status = RoadmapStatus.AHEAD;
  }

  return {
    actualProgress: parseFloat((actualProgress || 0).toFixed(2)), // Actual progress percentage
    expectedProgress: parseFloat((expectedProgress || 0).toFixed(2)), // Expected progress percentage
    status, // Status like 'On track', 'Delayed', or 'Ahead'
  };
};

export function addQuarterStatus(roadmap: IRoadmap): IRoadmap {
  roadmap.quarters = roadmap.quarters.map((quarter) => {
    quarter.status = getQuarterStatus(roadmap.target_year, quarter.name);
    return quarter;
  });

  return roadmap;
}

export function getQuarterStatus(year: number, quarter: QuarterType) {
  const { startOfCurrentQuarter, endOfCurrentQuarter } = getQuarterInfo();
  const { startDate, endDate } = getQuarterDates(year, quarter);

  if (endDate < startOfCurrentQuarter) {
    return QuarterStatus.PAST;
  } else if (endOfCurrentQuarter < startDate) {
    return QuarterStatus.FUTURE;
  }
  return QuarterStatus.PRESENT;
}

export function getQuarterDateRange(year: number, quarter: QuarterType) {
  let startDate: Date;
  let endDate: Date;

  switch (quarter) {
    case QuarterType.Q1:
      startDate = new Date(year, 0, 1); // January 1st
      endDate = new Date(year, 2, 31); // March 31st
      break;
    case QuarterType.Q2:
      startDate = new Date(year, 3, 1); // April 1st
      endDate = new Date(year, 5, 30); // June 30th
      break;
    case QuarterType.Q3:
      startDate = new Date(year, 6, 1); // July 1st
      endDate = new Date(year, 8, 30); // September 30th
      break;
    case QuarterType.Q4:
      startDate = new Date(year, 9, 1); // October 1st
      endDate = new Date(year, 11, 31); // December 31st
      break;
    default:
      throw new Error("Invalid quarter");
  }

  return {
    startDate: format(startDate, "yyyy-MM-dd"),
    endDate: format(endDate, "yyyy-MM-dd"),
  };
}
