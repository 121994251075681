import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Modal,
  Select,
  TextArea,
  TextInput,
  Button,
  LoadingSpinner,
} from "@/components/atoms";
import { RoadmapService } from "@/api/services";
import {
  IInitiative,
  INewInitiative,
  InitiativeStatus,
  IQuarter,
  QuarterStatus,
} from "@/models";
import { useAuth } from "@/contexts/auth.context";
import { useRoadmap } from "@/contexts/roadmap.context";
import { getQuarterDateRange, getQuarterStatus } from "@/common/utils";
import { Mixpanel, MixpanelEvents } from "@/mixpanel";

export enum OpenSourceType {
  FROM_EMPTY_SCREEN = "FROM_EMPTY_SCREEN",
  FROM_QUARTER_BAR = "FROM_QUARTER_BAR",
  FROM_INTIATIVE_ITEM = "FROM_INTIATIVE_ITEM",
}

type InitiativeFormInputType = INewInitiative;

interface Props {
  quarter: IQuarter;
  initiative?: Partial<IInitiative>;
  openSource?: OpenSourceType;
  isOpen: boolean;
  onClose: () => void;
}

const InitiativeFormModal: React.FC<Props> = ({
  quarter,
  initiative,
  openSource,
  isOpen,
  onClose,
}) => {
  const { user } = useAuth();
  const { quarterSelectOptions, roadmapUuid, roadmap, reloadRoadmap } =
    useRoadmap();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [updatedInitiative, setUpdatedInitiative] = useState<
    Partial<IInitiative> | undefined
  >(initiative);

  const originalQuarterOption = useMemo(
    () =>
      quarterSelectOptions.find(
        (item) => item.value === (initiative?.quarter_id || quarter.quarter_id)
      ),
    [quarterSelectOptions, initiative?.quarter_id, quarter.quarter_id]
  );

  const selectedQuarterOption = useMemo(
    () =>
      quarterSelectOptions.find(
        (item) =>
          item.value === (updatedInitiative?.quarter_id || quarter.quarter_id)
      ),
    [quarter.quarter_id, quarterSelectOptions, updatedInitiative?.quarter_id]
  );

  const isUpdating = useMemo(
    () => !!initiative?.initiative_id,
    [initiative?.initiative_id]
  );

  const isChanged = useMemo(
    () => JSON.stringify(initiative) !== JSON.stringify(updatedInitiative),
    [initiative, updatedInitiative]
  );

  const isChangeStatusToCompleted = useMemo(() => {
    if (
      !roadmap ||
      !isUpdating ||
      !originalQuarterOption ||
      !selectedQuarterOption
    ) {
      return false;
    }

    const originalQuarterOptionStatus = getQuarterStatus(
      roadmap.target_year,
      originalQuarterOption.label
    );

    const selectedQuarterOptionStatus = getQuarterStatus(
      roadmap.target_year,
      selectedQuarterOption.label
    );

    if (originalQuarterOptionStatus === QuarterStatus.PAST) {
      return false;
    }

    return selectedQuarterOptionStatus === QuarterStatus.PAST;
  }, [isUpdating, originalQuarterOption, selectedQuarterOption, roadmap]);

  const isChangeStatusToScheduled = useMemo(() => {
    if (
      !roadmap ||
      !isUpdating ||
      !originalQuarterOption ||
      !selectedQuarterOption
    ) {
      return false;
    }

    const originalQuarterOptionStatus = getQuarterStatus(
      roadmap.target_year,
      originalQuarterOption.label
    );

    const selectedQuarterOptionStatus = getQuarterStatus(
      roadmap.target_year,
      selectedQuarterOption.label
    );

    if (originalQuarterOptionStatus !== QuarterStatus.PAST) {
      return false;
    }

    return selectedQuarterOptionStatus !== QuarterStatus.PAST;
  }, [isUpdating, originalQuarterOption, selectedQuarterOption, roadmap]);

  const { startDate, endDate } = useMemo(() => {
    if (roadmap?.target_year && selectedQuarterOption?.label) {
      return getQuarterDateRange(
        roadmap?.target_year,
        selectedQuarterOption?.label
      );
    } else {
      return { startDate: undefined, endDate: undefined };
    }
  }, [roadmap?.target_year, selectedQuarterOption?.label]);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm<InitiativeFormInputType>({
    defaultValues: {
      title: initiative?.title,
      description: initiative?.description,
      targetDate: initiative?.target_date,
      quarterId: initiative?.quarter_id || quarter.quarter_id,
    },
  });

  const handleClose = useCallback(() => {
    setErrorMessage(null);
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit = useCallback(
    async (data: InitiativeFormInputType) => {
      if (!roadmap || !selectedQuarterOption) return;

      const selectedQuarterStatus = getQuarterStatus(
        roadmap.target_year,
        selectedQuarterOption.label
      );
      const { title, description, targetDate, quarterId: quarter_id } = data;

      setLoading(true);
      try {
        const response = initiative?.initiative_id
          ? await RoadmapService.updateInitiative(
              roadmapUuid,
              initiative.initiative_id,
              {
                title,
                description,
                targetDate,
                quarterId: quarter_id,
                status: isChangeStatusToCompleted
                  ? InitiativeStatus.COMPLETED
                  : isChangeStatusToScheduled
                  ? InitiativeStatus.SCHEDULED
                  : initiative.status,
              }
            )
          : await RoadmapService.createInitiative(roadmapUuid, {
              title,
              description,
              targetDate,
              quarterId: quarter_id,
              status:
                selectedQuarterStatus === QuarterStatus.PAST
                  ? InitiativeStatus.COMPLETED
                  : InitiativeStatus.SCHEDULED,
            });

        Mixpanel.identify(user?.id?.toString());
        if (initiative?.initiative_id) {
          Mixpanel.track(MixpanelEvents.INITIATIVE_UPDATE, {
            initiative: response,
            prev_initiative: initiative,
            email: user?.email,
            fromSource: openSource,
          });
        } else {
          Mixpanel.track(MixpanelEvents.INITIATIVE_CREATE, {
            initiative: response,
            email: user?.email,
            fromSource: openSource,
          });
        }

        if (response) {
          await reloadRoadmap();
        }

        handleClose();
      } catch (error) {
        setErrorMessage("Failed to create roadmap.");
      }
      setLoading(false);
    },
    [
      roadmap,
      selectedQuarterOption,
      initiative,
      roadmapUuid,
      isChangeStatusToCompleted,
      isChangeStatusToScheduled,
      user,
      handleClose,
      openSource,
      reloadRoadmap,
    ]
  );

  useEffect(() => {
    setValue("quarterId", quarter.quarter_id);
  }, [quarter.quarter_id, setValue]);

  return (
    <Modal
      className="max-w-[600px]"
      isOpen={isOpen}
      onClose={handleClose}
      showCloseButton={!loading}
      closeOnClickOutside={!loading}
    >
      <div>
        <h1 className="font-semibold text-[28px]">
          {isUpdating ? "Edit" : "New"} Initiative
        </h1>
        <p className="text-base text-typography-6E">
          Outline quarterly initiatives and target
        </p>
      </div>
      <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-center gap-6">
          <TextInput
            className="w-full"
            label="Title"
            type="text"
            {...register("title", {
              required: "Title is required",
            })}
            error={!!errors.title || !!errorMessage}
            errorMessage={errors.title?.message as string}
            onChangeCapture={() => setErrorMessage(null)}
            onChange={(e) => {
              setUpdatedInitiative((prev) => ({
                ...prev,
                title: e.target.value,
              }));
              setValue("title", e.target.value, { shouldValidate: true });
            }}
            disabled={loading}
          />
          <div className="w-full flex gap-4">
            <TextInput
              className="flex-1 w-full"
              label="Targeted release date"
              type="date"
              min={startDate}
              max={endDate}
              placeholder=""
              {...register("targetDate", {
                required: "Targeted release date is required",
              })}
              error={!!errors.targetDate || !!errorMessage}
              errorMessage={errors.targetDate?.message as string}
              onChangeCapture={() => setErrorMessage(null)}
              onChange={(e) => {
                setUpdatedInitiative((prev) => ({
                  ...prev,
                  target_date: e.target.value,
                }));
                setValue("targetDate", e.target.value, {
                  shouldValidate: true,
                });
              }}
              disabled={loading}
            />
            <Select
              className="flex-1 w-full"
              label="Quarter"
              options={[...quarterSelectOptions]}
              {...register("quarterId", {
                required: "Quarter is required",
              })}
              error={!!errors.quarterId || !!errorMessage}
              errorMessage={errors.quarterId?.message as string}
              onChangeCapture={() => setErrorMessage(null)}
              onChange={(e) => {
                setUpdatedInitiative((prev) => ({
                  ...prev,
                  target_date: "",
                  quarter_id: parseInt(e.target.value),
                }));
                setValue("targetDate", "", { shouldValidate: true });
              }}
              disabled={loading}
            />
          </div>
          <TextArea
            className="w-full"
            label="Description"
            placeholder="What is this initiative about?"
            rows={4}
            {...register("description", {})}
            error={!!errors.description || !!errorMessage}
            errorMessage={errors.description?.message as string}
            onChangeCapture={() => setErrorMessage(null)}
            onChange={(e) => {
              setUpdatedInitiative((prev) => ({
                ...prev,
                description: e.target.value,
              }));
            }}
            disabled={loading}
          />
        </div>
        {errorMessage && (
          <p className="text-sm text-center font-normal text-error mt-2">
            {errorMessage}
          </p>
        )}

        <div className="mt-6">
          {(isChangeStatusToCompleted || isChangeStatusToScheduled) && (
            <div className="my-2 flex items-center gap-2">
              <img
                src="/icons/exclamationmark.circle.svg"
                alt=""
                className="w-7 h-7"
              />
              <p className="text-sm text-typography-6E">
                Changing to {selectedQuarterOption?.label} will automatically
                mark this initiative as{" "}
                {isChangeStatusToCompleted ? "completed" : "scheduled"}.
              </p>
            </div>
          )}
          {isUpdating ? (
            <Button
              type="submit"
              className="w-full"
              disabled={loading || !isValid || !isChanged}
            >
              {loading ? (
                <div className="flex items-center justify-center gap-4">
                  <LoadingSpinner size={24} />
                  <span>Updating...</span>
                </div>
              ) : (
                "Update"
              )}
            </Button>
          ) : (
            <Button
              type="submit"
              className="w-full"
              disabled={loading || !isValid}
            >
              {loading ? (
                <div className="flex items-center justify-center gap-4">
                  <LoadingSpinner size={24} />
                  <span>Creating new...</span>
                </div>
              ) : (
                <div className="flex items-center justify-center gap-4">
                  <img
                    src="/icons/plus.circle.fill.svg"
                    alt="create new"
                    className="w-6 h-6"
                  />
                  <span>Create</span>
                </div>
              )}
            </Button>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default InitiativeFormModal;
