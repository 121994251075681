import { forwardRef, SelectHTMLAttributes, useMemo } from "react";
import classNames from "classnames";

interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  label: string;
  options: { label: string; value: string | number }[];
  error?: boolean;
  errorMessage?: string;
}

export const Select = forwardRef<HTMLSelectElement, ISelectProps>(
  (
    { className = "", label, options, error, errorMessage = "", ...rest },
    ref
  ) => {
    // Memoizing options to prevent unnecessary re-renders
    const renderedOptions = useMemo(
      () =>
        options.map(({ label, value }) => (
          <option value={value} key={`${label}-${value}`}>
            {label}
          </option>
        )),
      [options]
    );

    return (
      <div className={className}>
        <label className="block mb-2 text-sm font-medium text-typography-4D">
          {label}
        </label>
        <select
          ref={ref}
          className={classNames(
            "bg-gray-50 border font-bold text-typography-0C rounded-lg block w-full h-[52px] p-2.5",
            error ? "border-error" : "border-gray-300"
          )}
          {...rest}
        >
          {renderedOptions}
        </select>
        {errorMessage && <p className="text-error mt-1">{errorMessage}</p>}
      </div>
    );
  }
);
