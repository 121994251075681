import ApiService from "../api.base";
import EpicEndpoints from "../endpoints/epic.endpoint";
import { IEpic, IMilestone } from "@/models";

export class EpicService extends ApiService {

    static async createEpic(): Promise<IEpic> {
        return this.post(EpicEndpoints.CREATE_EPIC);
    }

    static async getAllEpics(): Promise<IEpic[]> {
        const data = await this.get(EpicEndpoints.GET_ALL_EPICS);
        return data.epics;
    }

    static async getEpic(presentableUuid: string): Promise<IEpic> {
        const response = await this.get(EpicEndpoints.GET_ALL_EPICS, { presentable_uuid: presentableUuid });
        return response.epic;
    }

    static async getEpicDetails(epicId: number): Promise<IMilestone[]> {
        const data = await this.get(EpicEndpoints.GET_EPIC_DETAILS, { 
            epic_id: epicId
         });
        return data.milestones;
    }
}
