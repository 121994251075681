class RoadmapsEndpoints {
  static CREATE = "roadmaps/create";
  static GET_ALL_ROADMAPS = "/roadmaps";
  static GET_ROADMAP_BY_UUID = (roadmap_uuid: string) =>
    `roadmaps/${roadmap_uuid}`;
  static CREATE_INITIATIVE = (roadmap_uuid: string) =>
    `roadmaps/${roadmap_uuid}/initiatives/create`;
  static UPDATE_INITIATIVE = (roadmap_uuid: string, initiative_id: number) =>
    `roadmaps/${roadmap_uuid}/initiatives/${initiative_id}/update`;
  static UPDATE_INITIATIVE_SATAUS = (
    roadmap_uuid: string,
    initiative_id: number
  ) => `roadmaps/${roadmap_uuid}/initiatives/${initiative_id}/status`;
  static DELETE_INITIATIVE = (roadmap_uuid: string, initiative_id: number) =>
    `roadmaps/${roadmap_uuid}/initiatives/${initiative_id}/delete`;
}

export default RoadmapsEndpoints;
