import React, { useEffect, useState } from "react";
import { IMilestone } from "@/models";
import { MilestoneService, EpicService } from "@/api/services";
import MilestoneComponent from "./MilestoneComponent";

interface OverviewComponentsProps {
  epicUuid: string;
  epicId: number;
}

const Overview: React.FC<OverviewComponentsProps> = ({ epicUuid, epicId }) => {
  const [milestones, setMilestones] = useState<IMilestone[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadMilestones = async () => {
      try {
        const epicDetails = await EpicService.getEpicDetails(epicId);

        setMilestones(epicDetails);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch milestones");
        setLoading(false);
      }
    };

    loadMilestones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const createNewMilestone = async () => {
    const tempMilestoneName = "Untitled milestone";

    try {
      // TODO: Deprecate the usage of epicUuid and instead use epicId.
      const newMilestone = await MilestoneService.createMilestone(
        epicUuid,
        tempMilestoneName
      );

      setMilestones((prevMilestones) => [...prevMilestones, newMilestone]);
    } catch {
      return <h3>Something went wrong with the milestone creation</h3>;
    }
  };

  return (
    <div>
      <h2>Overview</h2>

      <div>
        {milestones.map((milestone) => (
          <MilestoneComponent
            key={milestone.milestone_id}
            componentMilestone={milestone}
          />
        ))}
      </div>

      <div>
        <button onClick={createNewMilestone}>Create Milestone</button>
      </div>
    </div>
  );
};

export default Overview;
