import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService, UserService } from "@/api/services";
import { IUser, IStoredToken, AuthState } from "@/models";
import {
  getStoredToken,
  removeStoredToken,
  storeToken,
  removeRefreshToken,
  isNoneAuthPath,
} from "@/common/utils";
import { Mixpanel, MixpanelEvents } from "@/mixpanel";

// Context Interface
interface AuthContextProps {
  user: IUser | null;
  authState: AuthState;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<void>;
  refreshToken: () => Promise<void>;
  setUser: (user: IUser) => void;
}

// Create Context
const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// Auth Provider Component
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [authState, setAuthState] = useState<AuthState>(AuthState.LOADING);

  const navigate = useNavigate();
  const location = useLocation();

  // Logout Function
  const logout = useCallback(async () => {
    setUser(null);
    removeStoredToken();
    removeRefreshToken();
    setAuthState(AuthState.UNAUTHENTICATED);
    Mixpanel.reset();

    if (!isNoneAuthPath(location.pathname)) {
      navigate("/login");
    }

    try {
      await AuthService.logout();
    } catch (error) {
      console.error("Logout failed:", error);
    }
  }, [location, navigate]);

  // Utility: Fetch Token from Storage
  const fetchTokenFromStorage = (): IStoredToken | null => {
    const storedToken = getStoredToken();
    return storedToken
      ? {
          accessToken: storedToken.accessToken,
          accessTokenExpiresAt: new Date(storedToken.accessTokenExpiresAt),
        }
      : null;
  };

  // Utility: Common error handling function
  const handleAuthError = (error: any, eventName: MixpanelEvents) => {
    Mixpanel.track(eventName, {
      error: {
        data: error.response?.data,
        status: error.response?.status,
      },
    });
  };

  // Login Function
  const login = useCallback(async (email: string, password: string) => {
    try {
      const response = await AuthService.login(email, password);

      const token: IStoredToken = {
        accessToken: response.accessToken,
        accessTokenExpiresAt: new Date(response.expiresAt),
      };

      storeToken(token);
      setUser(response.user);
      setAuthState(AuthState.AUTHENTICATED);

      Mixpanel.identify(response.user.id);
      Mixpanel.track(MixpanelEvents.LOGIN_SUCCESS, {
        email: response.user.email,
      });
    } catch (error) {
      handleAuthError(error, MixpanelEvents.LOGIN_FAILURE);
      throw error;
    }
  }, []);

  // Register Function
  const register = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string
    ) => {
      try {
        const response = await AuthService.register(
          email,
          password,
          firstName,
          lastName
        );

        const token: IStoredToken = {
          accessToken: response.accessToken,
          accessTokenExpiresAt: new Date(response.expiresAt),
        };

        storeToken(token);
        setUser(response.user);
        setAuthState(AuthState.AUTHENTICATED);

        Mixpanel.identify(response.user.id);
        Mixpanel.people.set(response.user);
        Mixpanel.track(MixpanelEvents.REGISTER_SUCCESS, {
          user: response.user,
          email: response.user.email,
        });
      } catch (error) {
        handleAuthError(error, MixpanelEvents.REGISTER_FAILURE);
        throw error;
      }
    },
    []
  );

  // Refresh Token Function
  const refreshToken = useCallback(async () => {
    try {
      const response = await AuthService.refreshToken();

      const refreshedToken: IStoredToken = {
        accessToken: response.accessToken,
        accessTokenExpiresAt: new Date(response.expiresAt),
      };

      storeToken(refreshedToken);
      setUser(response.user);
      setAuthState(AuthState.AUTHENTICATED);
    } catch (error) {
      await logout();
    }
  }, [logout]);

  // Initial User Load
  useEffect(() => {
    const initAuth = async () => {
      const storedToken = fetchTokenFromStorage();
      if (!storedToken || storedToken.accessTokenExpiresAt < new Date()) {
        await refreshToken();
        return;
      }

      try {
        const userData = await UserService.me();
        setUser(userData);
        setAuthState(AuthState.AUTHENTICATED);
      } catch (error) {
        handleAuthError(error, MixpanelEvents.LOGIN_FAILURE);
      }
    };

    initAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Refresh token every 10 minutes if authenticated
  useEffect(() => {
    if (authState === AuthState.AUTHENTICATED) {
      const interval = setInterval(() => {
        refreshToken();
      }, 10 * 60 * 1000); // 10 minutes

      return () => clearInterval(interval);
    }
  }, [authState, refreshToken]);

  return (
    <AuthContext.Provider
      value={{
        user,
        authState,
        login,
        logout,
        register,
        refreshToken,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom Hook for Consuming AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
