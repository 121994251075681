import classNames from "classnames";
import { ButtonHTMLAttributes, forwardRef } from "react";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  ({ className = "", children, disabled, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        className={classNames(
          "text-white bg-primary hover:bg-primary-dark focus:ring-primary-light",
          "focus:ring-4 focus:outline-none disabled:bg-disable h-[49px] rounded-lg font-semibold text-[16px] text-center flex items-center justify-center gap-2",
          className,
          { "cursor-not-allowed": disabled }
        )}
        disabled={disabled}
        aria-disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }
);
