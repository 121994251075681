export interface IInitiative {
  initiative_id: number;
  title: string;
  description: string;
  status: InitiativeStatus;
  target_date: string;
  epics_count: number;
  quarter_id: number;
}

export interface INewInitiative {
  title: string;
  description: string;
  targetDate: string;
  quarterId: number;
  status?: InitiativeStatus;
}

export enum InitiativeStatus {
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  ON_TRACK = "ON_TRACK",
  SCHEDULED = "SCHEDULED",
  AT_RISK = "AT_RISK",
}
