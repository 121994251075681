import mixpanel, { Dict } from "mixpanel-browser";

const mixpanel_token = process.env.REACT_APP_MIXPANEL_TOKEN || "";

mixpanel.init(mixpanel_token, {
  debug: true,
  track_pageview: "url-with-path",
  persistence: "localStorage",
});

export enum MixpanelEvents {
  REGISTER_SUCCESS = "Register Success",
  REGISTER_FAILURE = "Register Failure",
  LOGIN_SUCCESS = "Login Success",
  LOGIN_FAILURE = "Login Failure",
  ROADMAP_CREATE = "Roadmap Create",
  INITIATIVE_CREATE = "Initiative Create",
  INITIATIVE_UPDATE = "Initiative Update",
}

export const Mixpanel = {
  identify: (id?: string) => {
    mixpanel.identify(id);
  },
  alias: (alias: string) => {
    mixpanel.alias(alias);
  },
  track: (event_name: MixpanelEvents, properties?: Dict) => {
    mixpanel.track(event_name, properties);
  },
  people: {
    set: (props: Dict) => {
      mixpanel.people.set(props);
    },
  },
  reset: () => mixpanel.reset,
};
