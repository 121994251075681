import React, { useEffect, useState } from "react";
import { ProgressBar } from "@/components/atoms";
import { MetricsService } from "@/api/services";

interface MetricsComponentsProps {
  epicId: number;
}

const Metrics: React.FC<MetricsComponentsProps> = ({ epicId }) => {
  const [totalStories, setTotalStories] = useState<number>(0);
  const [completedStories, setCompletedStories] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { totalStories, completedStories } =
          await MetricsService.getStoriesSummary(epicId);
        setTotalStories(totalStories);
        setCompletedStories(completedStories);
      } catch (error) {
        console.error("Failed to fetch stories summary", error);
      }
    };

    fetchData();
  }, [epicId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
      }}
    >
      <h1 style={{ marginBottom: "20px" }}>Metrics</h1>
      <div style={{ width: "80%" }}>
        <ProgressBar
          total={totalStories}
          completed={completedStories}
        />
      </div>
    </div>
  );
};

export default Metrics;
