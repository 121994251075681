import ApiService from "../api.base";
import AuthEndpoints from "../endpoints/auth.endpoints";

export class AuthService extends ApiService {
  static async register(
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ): Promise<any> {
    const data = { email, password, firstName, lastName };
    return this.post(AuthEndpoints.REGISTER, data);
  }

  static async login(email: string, password: string): Promise<any> {
    const data = { email, password };
    return this.post(AuthEndpoints.LOGIN, data);
  }

  static async refreshToken(): Promise<any> {
    return this.post(AuthEndpoints.REFRESH);
  }

  static async logout(): Promise<any> {
    return this.post(AuthEndpoints.LOGOUT);
  }
}
