import { IStoredToken } from "@/models";
import { LocalStorageKeys } from "@/common/consts";

export const getStoredToken = () => {
  const storedToken = localStorage.getItem(LocalStorageKeys.token);

  if (storedToken === null) {
    return null;
  }

  const parsed = JSON.parse(storedToken);

  if (parsed === null) {
    return null;
  }

  return parsed;
};

export const storeToken = (token: IStoredToken) => {
  localStorage.setItem(LocalStorageKeys.token, JSON.stringify(token));
};

export const removeStoredToken = () => {
  localStorage.removeItem(LocalStorageKeys.token);
};
