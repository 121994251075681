import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/auth.context";
import Layout from "./components/Layout";
import { AuthState } from "./models/auth.state";

import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import EpicDetailsPage from "./pages/EpicDetailsPage";
import EpicsPage from "./pages/EpicsPage";
import RoadmapsPage from "./pages/RoadmapsPage";
import RoadmapOverviewPage from "./pages/RoadmapOverviewPage";

const RouteWithAuth: React.FC<{ element: JSX.Element; authRequired: boolean }> = ({ element, authRequired }) => {
  const { user, authState } = useAuth();

  if (authState === AuthState.LOADING) {
    return <div>Loading...</div>;
  }

  if (authRequired && !user) {
    return <Navigate to="/login" />;
  } else if (!authRequired && user) {
    return <Navigate to="/" />;
  }

  return element;
};


const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/roadmaps" />} />
      <Route path="/login" element={<RouteWithAuth element={<LoginPage />} authRequired={false} />} />
      <Route path="/register" element={<RouteWithAuth element={<RegisterPage />} authRequired={false} />} />
      
      {/* Protected routes */}
      <Route path="epics/:epicUuid/*" element={<RouteWithAuth element={<EpicDetailsPage />} authRequired={true} />} />
      <Route path="/" element={<RouteWithAuth element={<Layout />} authRequired={true} />}>
        <Route path="epics" element={<RouteWithAuth element={<EpicsPage />} authRequired={true} />} />
        <Route path="roadmaps" element={<RouteWithAuth element={<RoadmapsPage />} authRequired={true} />} />
        <Route path="roadmaps/:roadmapUuid/overview" element={<RouteWithAuth element={<RoadmapOverviewPage />} authRequired={true} />} />
      </Route>
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </Router>
  );
};

export default App;
