import React from "react";

const EpicSettings: React.FC = () => {
  return (
    <div>
      <h2>Epic Settings</h2>
      <p>This is the epic settings content.</p>
    </div>
  );
};

export default EpicSettings;
