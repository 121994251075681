import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RoadmapService } from "@/api/services";
import {
  Button,
  LoadingSpinner,
  Modal,
  Select,
  TextInput,
} from "@/components/atoms";
import { roadmapTargetYearOptions } from "@/common/consts";
import { useAuth } from "@/contexts/auth.context";
import { Mixpanel, MixpanelEvents } from "@/mixpanel";

type LoadmapFormInputType = {
  name: string;
  target_year: number;
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const RoadmapFormModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { user } = useAuth();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm<LoadmapFormInputType>({
    defaultValues: {
      name: "",
      target_year: new Date().getFullYear(),
    },
  });

  const handleClose = useCallback(() => {
    setErrorMessage(null);
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit = useCallback(
    async (data: LoadmapFormInputType) => {
      const { name, target_year } = data;

      setLoading(true);
      try {
        const response = await RoadmapService.createRoadmap(name, target_year);

        Mixpanel.identify(user?.id?.toString());
        Mixpanel.track(MixpanelEvents.ROADMAP_CREATE, {
          roadmap: response,
          email: user?.email,
        });

        handleClose();
        navigate(`/roadmaps/${response.roadmap_uuid}/overview`);
      } catch (error) {
        setErrorMessage("Failed to create roadmap.");
      }
      setLoading(false);
    },
    [handleClose, navigate, user]
  );

  return (
    <Modal
      className="max-w-[600px]"
      isOpen={isOpen}
      onClose={handleClose}
      showCloseButton={!loading}
      closeOnClickOutside={!loading}
    >
      <div>
        <h1 className="font-semibold text-[28px]">New Roadmap</h1>
        <p className="text-base text-typography-6E">
          Create initiatives, tracked quarterly.
        </p>
      </div>
      <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center gap-6">
          <TextInput
            className="w-full"
            label="Name"
            type="text"
            placeholder="What to call this roadmap"
            {...register("name", {
              required: "Name is required",
            })}
            error={!!errors.name || !!errorMessage}
            errorMessage={errors.name?.message as string}
            onChangeCapture={() => setErrorMessage(null)}
            disabled={loading}
          />
          <Select
            className="w-1/2"
            label="Target Year"
            options={roadmapTargetYearOptions}
            {...register("target_year", {
              required: "Target year is required",
            })}
            error={!!errors.target_year || !!errorMessage}
            errorMessage={errors.target_year?.message as string}
            onChangeCapture={() => setErrorMessage(null)}
            disabled={loading}
          />
        </div>
        {errorMessage && (
          <p className="text-sm text-center font-normal text-error mt-2">
            {errorMessage}
          </p>
        )}

        <div className="mt-6">
          <Button
            type="submit"
            className="w-full"
            disabled={loading || !isValid}
          >
            {loading ? (
              <div className="flex items-center justify-center gap-4">
                <LoadingSpinner size={24} />
                <span>Creating new...</span>
              </div>
            ) : (
              <div className="flex items-center justify-center gap-4">
                <img
                  src="/icons/plus.circle.fill.svg"
                  alt="create new"
                  className="w-6 h-6"
                />
                <span>Create</span>
              </div>
            )}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default RoadmapFormModal;
