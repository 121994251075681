import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "@/contexts/auth.context";
import { Button, LoadingSpinner, TextInput } from "@/components/atoms";

type LoginFormInputType = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const { login } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<LoginFormInputType>();

  const onSubmit = async (data: LoginFormInputType) => {
    const { email, password } = data;

    setLoading(true);
    try {
      await login(email, password);
    } catch (error) {
      setErrorMessage("Invalid credentials. Try again.");
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <img src="/logo-circular.png" alt="Logo" className="w-16 h-16 mb-12" />

      <h1 className="text-xl font-bold leading-tight tracking-tight text-typography-0C md:text-2xl">
        Plan. Execute. Succeed.
      </h1>

      <p className="text-lg font-normal leading-tight tracking-tight text-typography-5D md:text-xl">
        Login to your account
      </p>

      <form
        className="space-y-4 md:space-y-6 w-full md:w-96 mt-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextInput
          label="Email address"
          type="email"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: "Enter a valid email address",
            },
          })}
          error={!!errors.email || !!errorMessage}
          errorMessage={errors.email?.message as string}
          onChangeCapture={() => setErrorMessage(null)}
        />
        <div>
          <TextInput
            label="Password"
            type="password"
            {...register("password", {
              required: "Password is required",
            })}
            error={!!errors.password || !!errorMessage}
            errorMessage={errors.password?.message as string}
            onChangeCapture={() => setErrorMessage(null)}
          />
          {errorMessage && (
            <p className="text-sm font-normal text-error mt-2">
              {errorMessage}
            </p>
          )}
        </div>
        <div className="py-6">
          <Button
            type="submit"
            className="w-full"
            disabled={loading || !isValid}
          >
            {loading ? (
              <div className="flex items-center justify-center gap-4">
                <LoadingSpinner size={24} />
                <span>Authenticating...</span>
              </div>
            ) : (
              "Log in"
            )}
          </Button>

          {/* Signup Prompt */}
          <p className="text-sm font-normal text-typography-6E mt-4">
            Don’t have an account yet?{" "}
            <a href="/register" className="font-bold text-blue hover:underline">
              Sign up
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
