import ApiService from "../api.base";
import RoadmapsEndpoints from "../endpoints/roadmap.endpoints";
import {
  IInitiative,
  INewInitiative,
  IRoadmap,
  INewRoadmap,
  IRoadmapMeta,
  InitiativeStatus,
} from "@/models";

export class RoadmapService extends ApiService {
  static async createRoadmap(
    name: string,
    target_year: number
  ): Promise<INewRoadmap> {
    return this.post(RoadmapsEndpoints.CREATE, {
      name,
      target_year,
    });
  }

  static async fetchAllRoadmaps(): Promise<IRoadmapMeta[]> {
    const data = await this.get(RoadmapsEndpoints.GET_ALL_ROADMAPS);
    return data.roadmaps;
  }

  static async fetchRoadmap(roadmapUuid: string): Promise<IRoadmap> {
    return this.get(RoadmapsEndpoints.GET_ROADMAP_BY_UUID(roadmapUuid));
  }

  static async createInitiative(
    roadmapUuid: string,
    newInitiative: INewInitiative
  ): Promise<IInitiative> {
    return await this.post(RoadmapsEndpoints.CREATE_INITIATIVE(roadmapUuid), {
      title: newInitiative.title,
      description: newInitiative.description,
      target_date: newInitiative.targetDate,
      quarter_id: newInitiative.quarterId,
      status: newInitiative.status,
    });
  }

  static async updateInitiative(
    roadmapUuid: string,
    initiative_id: number,
    newInitiative: INewInitiative
  ): Promise<IInitiative> {
    return await this.put(
      RoadmapsEndpoints.UPDATE_INITIATIVE(roadmapUuid, initiative_id),
      {
        title: newInitiative.title,
        description: newInitiative.description,
        target_date: newInitiative.targetDate,
        quarter_id: newInitiative.quarterId,
        status: newInitiative.status,
      }
    );
  }

  static async updateInitiativeStatus(
    roadmapUuid: string,
    initiative_id: number,
    status: InitiativeStatus
  ): Promise<IInitiative> {
    return await this.patch(
      RoadmapsEndpoints.UPDATE_INITIATIVE_SATAUS(roadmapUuid, initiative_id),
      {
        status,
      }
    );
  }

  static async deleteInitiative(
    roadmapUuid: string,
    initiative_id: number
  ): Promise<{ message: string }> {
    return await this.delete(
      RoadmapsEndpoints.DELETE_INITIATIVE(roadmapUuid, initiative_id)
    );
  }
}
