export interface IStory {
  story_id: string;
  milestone_id: string;
  title: string;
  description?: string;
  status: StoryStatus;
  created_at: string;
  updated_at: string;
}

export enum StoryStatus {
  TODO = "todo",
  IN_PROGRESS = "in_progress",
  DONE = "done",
}
