import React, { useCallback, useState } from "react";
import classNames from "classnames";
import {
  IInitiative,
  InitiativeStatus,
  IQuarter,
  QuarterStatus,
} from "@/models";
import { formatToLongDate } from "@/common/utils";
import { Dropdown, LoadingSpinner } from "@/components/atoms";
import { RoadmapService } from "@/api/services";
import { useRoadmap } from "@/contexts/roadmap.context";
import { useAuth } from "@/contexts/auth.context";
import { Mixpanel, MixpanelEvents } from "@/mixpanel";

interface InitiativeProps {
  quarter: IQuarter;
  initiative: IInitiative;
  onClick?: () => void;
}

const Initiative: React.FC<InitiativeProps> = ({
  quarter,
  initiative,
  onClick,
}) => {
  const { roadmapUuid, reloadRoadmap } = useRoadmap();
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);

  const updateInitiativeStatus = useCallback(
    async (initiative: IInitiative, status: InitiativeStatus) => {
      setLoading(true);
      try {
        const response = await RoadmapService.updateInitiativeStatus(
          roadmapUuid,
          initiative.initiative_id,
          status
        );

        Mixpanel.identify(user?.id?.toString());
        Mixpanel.track(MixpanelEvents.INITIATIVE_UPDATE, {
          initiative: response,
          prev_initiative: initiative,
          email: user?.email,
        });
        if (response) {
          reloadRoadmap();
        }
      } catch (error) {}
      setLoading(false);
    },
    [reloadRoadmap, roadmapUuid, user]
  );

  const deleteInitiative = useCallback(
    async (initiative: IInitiative) => {
      setLoading(true);
      try {
        const response = await RoadmapService.deleteInitiative(
          roadmapUuid,
          initiative.initiative_id
        );

        if (response.message === "success") {
          reloadRoadmap();
        }
      } catch (err) {}
      setLoading(false);
    },
    [reloadRoadmap, roadmapUuid]
  );

  return (
    <div
      className="relative w-[360px] h-[160px] p-4 border border-gray-medium-light rounded-lg cursor-pointer"
      onClick={onClick}
    >
      <div className="absolute top-2 right-4">
        {loading ? (
          <LoadingSpinner size={20} />
        ) : (
          <Dropdown
            button={
              <button className="h-6">
                <img src="/icons/ellipsis.svg" alt="menu" />
              </button>
            }
          >
            <div className="relative">
              <div className="absolute -mt-2 w-0 h-0 left-1/2 -translate-x-1/2 border-gray-super-light border-x-[8px] border-x-transparent border-b-[9px]" />
              <div
                className="w-[180px] px-2 py-3 bg-white border border-gray-super-light rounded-lg flex flex-col gap-2"
                style={{
                  boxShadow: "0px 4px 29.3px 0px rgba(0, 0, 0, 0.2)",
                }}
              >
                {initiative.status !== InitiativeStatus.COMPLETED && (
                  <div
                    className="px-2 py-1 font-semibold text-[13px] text-green cursor-pointer rounded-md hover:bg-light-blue"
                    onClick={() =>
                      updateInitiativeStatus(
                        initiative,
                        InitiativeStatus.COMPLETED
                      )
                    }
                  >
                    Mark as completed
                  </div>
                )}
                {quarter.status !== QuarterStatus.PAST && (
                  <>
                    {initiative.status !== InitiativeStatus.SCHEDULED && (
                      <div
                        className="px-2 py-1 font-semibold text-[13px] text-purple cursor-pointer rounded-md hover:bg-light-blue"
                        onClick={() =>
                          updateInitiativeStatus(
                            initiative,
                            InitiativeStatus.SCHEDULED
                          )
                        }
                      >
                        Scheduled
                      </div>
                    )}
                    {initiative.status !== InitiativeStatus.ON_TRACK && (
                      <div
                        className="px-2 py-1 font-semibold text-[13px] text-blue cursor-pointer rounded-md hover:bg-light-blue"
                        onClick={() =>
                          updateInitiativeStatus(
                            initiative,
                            InitiativeStatus.ON_TRACK
                          )
                        }
                      >
                        On track
                      </div>
                    )}
                    {initiative.status !== InitiativeStatus.AT_RISK && (
                      <div
                        className="px-2 py-1 font-semibold text-[13px] text-yellow cursor-pointer rounded-md hover:bg-light-blue"
                        onClick={() =>
                          updateInitiativeStatus(
                            initiative,
                            InitiativeStatus.AT_RISK
                          )
                        }
                      >
                        At risk
                      </div>
                    )}
                  </>
                )}
                {initiative.status !== InitiativeStatus.CANCELED && (
                  <div
                    className="px-2 py-1 font-semibold text-[13px] text-gray-dark cursor-pointer rounded-md hover:bg-light-blue"
                    onClick={() =>
                      updateInitiativeStatus(
                        initiative,
                        InitiativeStatus.CANCELED
                      )
                    }
                  >
                    Cancel
                  </div>
                )}
                <div
                  className="px-2 py-1 font-semibold text-[13px] text-error cursor-pointer rounded-md hover:bg-light-blue"
                  onClick={() => deleteInitiative(initiative)}
                >
                  Delete
                </div>
              </div>
            </div>
          </Dropdown>
        )}
      </div>

      <div className="flex justify-between gap-2">
        <div className="pt-2">
          <h2 className="font-medium text-lg leading-[20px] mb-2 line-clamp-2">
            {initiative.title}
          </h2>
          <div className="mt-3 w-fit px-2 py-[2px] bg-gray-bold-light rounded-full flex items-center gap-1">
            <img
              src="/icons/calendar.badge.clock.svg"
              alt="calendar"
              className="w-[14px] h-[12px]"
            />
            <p className="font-semibold text-[11px] text-typography-3A">
              {formatToLongDate(initiative.target_date)}
            </p>
          </div>
        </div>

        <div className="pt-6">
          <div
            className={classNames(
              "px-3 py-1 font-extrabold text-[11px] rounded",
              initiative.status === InitiativeStatus.SCHEDULED &&
                "bg-purple text-white",
              initiative.status === InitiativeStatus.COMPLETED &&
                "bg-green text-white",
              initiative.status === InitiativeStatus.ON_TRACK &&
                "bg-blue text-white",
              initiative.status === InitiativeStatus.AT_RISK &&
                "bg-red text-white",
              initiative.status === InitiativeStatus.CANCELED &&
                "bg-gray text-white"
            )}
          >
            <p className="whitespace-nowrap">
              {initiative.status.replaceAll("_", " ")}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <p className="text-[14px] text-typography-57 line-clamp-2">
          {initiative.description}
        </p>
      </div>
    </div>
  );
};

export default Initiative;
