import React, { useMemo } from "react";
import { timeAgo, calculateRoadmapProgress } from "@/common/utils";
import { useRoadmap } from "@/contexts/roadmap.context";
import Quarter from "./Quarter";
import { ProgressBar, LoadingSpinner } from "@/components/atoms";
import { RoadmapStatus } from "@/models";
import Breadcrumb from "@/components/atoms/Breadcrumb";

const RoadmapOverview: React.FC = () => {
  const { roadmap, error } = useRoadmap();

  const { actualProgress, expectedProgress, status } = useMemo(
    () =>
      roadmap
        ? calculateRoadmapProgress(roadmap)
        : {
            actualProgress: 0,
            expectedProgress: 0,
            status: "",
          },
    [roadmap]
  );

  const { color, icon } = useMemo(() => {
    let color;
    let icon;

    if (status === RoadmapStatus.AHEAD) {
      color = "#34A853";
      icon = "arrow.up.circle.fill";
    } else if (status === RoadmapStatus.ON_TRACK) {
      color = "#1A73E8";
      icon = "checkmark.shield.fill";
    } else if (status === RoadmapStatus.DELAYED) {
      color = "#E3170A";
      icon = "hourglass";
    }

    return { color, icon };
  }, [status]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!roadmap) {
    return (
      <div className="flex justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      {/* nav breadcrumbs */}
      <Breadcrumb
        items={[
          { name: "All Roadmaps", to: "/roadmaps" },
          { name: roadmap.name, to: undefined },
        ]}
      />

      {/* header - name & statistics */}
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between gap-6 py-2">
          <div className="w-[77px] h-[77px] rounded-full bg-gradient flex items-center justify-center">
            <div className="w-[75px] h-[75px] rounded-full bg-white flex items-center justify-center">
              <p style={{ fontFamily: "Share Tech Mono", fontSize: "28px" }}>
                {roadmap.target_year}
              </p>
            </div>
          </div>

          <div className="flex flex-col">
            <h2 className="font-medium text-[26px]">{roadmap.name}</h2>
            <p className="text-lg text-typography-5D">
              Last updated {timeAgo(roadmap.updated_at)}
            </p>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="w-[90px] h-[70px] rounded-md bg-gray-light flex flex-col items-center justify-center">
            <h2 className="font-medium text-[22px]">
              {roadmap.statistics.canceled}
            </h2>
            <p className="text-sm text-typography-58">Canceled</p>
          </div>
          <div className="w-[90px] h-[70px] rounded-md bg-gray-light flex flex-col items-center justify-center">
            <h2 className="font-medium text-[22px]">
              {roadmap.statistics.initiatives}
            </h2>
            <p className="text-sm text-typography-58">
              Initiative{roadmap.statistics.initiatives > 1 && "s"}
            </p>
          </div>
          <div className="w-[90px] h-[70px] rounded-md bg-gray-light flex flex-col items-center justify-center">
            <h2 className="font-medium text-[22px]">
              {roadmap.statistics.completed}
            </h2>
            <p className="text-sm text-typography-58">Completed</p>
          </div>
        </div>
      </div>

      {/* progress bar */}
      <div className="py-8">
        <ProgressBar
          total={100}
          completed={actualProgress}
          expected={expectedProgress}
          status={status}
          hexColor={color}
          statusIcon={icon}
          showStatus
          showDateRange
        />
      </div>

      {/* quarters */}
      <div className="py-8 flex flex-col gap-4">
        {roadmap.quarters.map((quarter) => (
          <Quarter quarter={quarter} key={`quarter-${quarter.quarter_id}`} />
        ))}
      </div>
    </div>
  );
};

export default RoadmapOverview;
