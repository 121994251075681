import axios from "axios";
import { getStoredToken, removeStoredToken } from "@/common/utils";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const storedToken = getStoredToken();
    if (storedToken && storedToken?.accessToken) {
      config.headers["Authorization"] = `Bearer ${storedToken.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      removeStoredToken();
      if (!error.response?.config?.url?.includes("/auth")) {
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
