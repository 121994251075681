import { QuarterType } from "@/models";

export const timeAgo = (date: Date | string): string => {
  const now = new Date();
  const before = new Date(date);
  const seconds = Math.floor((now.getTime() - before.getTime()) / 1000);

  const intervals: { [key: string]: number } = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (const [unit, value] of Object.entries(intervals)) {
    const interval = Math.floor(seconds / value);
    if (interval >= 1) {
      return `${interval} ${unit}${interval > 1 ? "s" : ""} ago`;
    }
  }

  return "just now";
};

export const getQuarterInfo = (): {
  currentQuarter: QuarterType;
  daysLeftInQuarter: number;
  daysPassedInQuarter: number;
  startOfCurrentQuarter: Date;
  endOfCurrentQuarter: Date;
} => {
  const now = new Date();
  const month: number = now.getMonth() + 1; // Months are 0-based, so we add 1
  const year: number = now.getFullYear();
  let currentQuarter: QuarterType;
  let startOfCurrentQuarter: Date;
  let endOfCurrentQuarter: Date;

  // Determine the current quarter based on the month
  if (month >= 1 && month <= 3) {
    currentQuarter = QuarterType.Q1;
    startOfCurrentQuarter = new Date(year, 0, 1); // January 1
    endOfCurrentQuarter = new Date(year, 2, 31, 23, 59, 59); // March 31
  } else if (month >= 4 && month <= 6) {
    currentQuarter = QuarterType.Q2;
    startOfCurrentQuarter = new Date(year, 3, 1); // April 1
    endOfCurrentQuarter = new Date(year, 5, 30, 23, 59, 59); // June 30
  } else if (month >= 7 && month <= 9) {
    currentQuarter = QuarterType.Q3;
    startOfCurrentQuarter = new Date(year, 6, 1); // July 1
    endOfCurrentQuarter = new Date(year, 8, 30, 23, 59, 59); // September 30
  } else {
    currentQuarter = QuarterType.Q4;
    startOfCurrentQuarter = new Date(year, 9, 1); // October 1
    endOfCurrentQuarter = new Date(year, 11, 31, 23, 59, 59); // December 31
  }

  // Calculate days left in the current quarter
  const daysLeft: number = Math.ceil(
    (endOfCurrentQuarter.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
  );

  // Calculate days passed in the current quarter
  const daysPassed: number = Math.ceil(
    (now.getTime() - startOfCurrentQuarter.getTime()) / (1000 * 60 * 60 * 24)
  );

  return {
    currentQuarter,
    daysLeftInQuarter: daysLeft,
    daysPassedInQuarter: daysPassed,
    startOfCurrentQuarter,
    endOfCurrentQuarter,
  };
};

export const formatToLongDate = (dateStr: string | Date): string => {
  const date = new Date(dateStr);

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export function getQuarterDates(
  year: number,
  quarter: QuarterType
): { startDate: Date; endDate: Date } {
  let startDate: Date;
  let endDate: Date;

  switch (quarter) {
    case QuarterType.Q1:
      startDate = new Date(year, 0, 1); // January 1st
      endDate = new Date(year, 2, 31, 23, 59, 59); // March 31st
      break;
    case QuarterType.Q2:
      startDate = new Date(year, 3, 1); // April 1st
      endDate = new Date(year, 5, 30, 23, 59, 59); // June 30th
      break;
    case QuarterType.Q3:
      startDate = new Date(year, 6, 1); // July 1st
      endDate = new Date(year, 8, 30, 23, 59, 59); // September 30th
      break;
    case QuarterType.Q4:
      startDate = new Date(year, 9, 1); // October 1st
      endDate = new Date(year, 11, 31, 23, 59, 59); // December 31st
      break;
    default:
      throw new Error("Invalid quarter type");
  }

  return { startDate, endDate };
}
