import ApiService from "../api.base";
import MilestoneEndpoints from "../endpoints/milestone.endpoint";
import { IMilestone, IStory } from "@/models";

export class MilestoneService extends ApiService {
  // TODO: Instead of using epicUuid, use epicId.
  static async createMilestone(
    epicUuid: string,
    name: string
  ): Promise<IMilestone> {
    const response = await this.post(MilestoneEndpoints.CREATE, {
      epic_uuid: epicUuid,
      name,
    });

    return response.milestone;
  }

  static async createStory(
    milestoneId: string,
    title: string
  ): Promise<IStory> {
    const response = await this.post(MilestoneEndpoints.CREATE_STORY, {
      milestone_id: milestoneId,
      title,
    });

    return response.story;
  }
}

