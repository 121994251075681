import React, { useMemo } from "react";

interface ProgressBarProps {
  completed: number;
  expected?: number;
  total: number;
  height?: number;
  color?: string;
  hexColor?: string;
  status?: string;
  statusIcon?: string;
  showPercentage?: boolean;
  showStatus?: boolean;
  showDateRange?: boolean;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  completed,
  expected = 0,
  total = 100,
  height = 12,
  hexColor = "#E3170A",
  status = "",
  statusIcon = "",
  showPercentage = true,
  showStatus = false,
  showDateRange = false,
}) => {
  // Memoize the calculated percentages for performance
  const { completedPercent, expectedPercent } = useMemo(() => {
    const completedPercent = Math.floor((completed / total) * 100);
    const expectedPercent = Math.floor((expected / total) * 100);
    return { completedPercent, expectedPercent };
  }, [completed, expected, total]);

  return (
    <div
      className="relative w-full"
      role="progressbar"
      aria-valuenow={completedPercent}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      <div className="flex items-center justify-end">
        {showStatus && (!!expected || !!completed) && (
          <div
            className="h-[26px] text-[9.7px] font-extrabold flex items-center justify-center gap-2 text-white text-xs px-3 rounded-full"
            style={{ backgroundColor: hexColor }}
          >
            {statusIcon && (
              <img
                src={`/icons/${statusIcon}.svg`}
                alt="status"
                className="w-3 h-3"
              />
            )}
            <span className="uppercase">{status}</span>
          </div>
        )}
      </div>
      <div className="relative mt-2 mb-12">
        {showPercentage && (!!expected || !!completed) && (
          <div
            className="absolute top-0 -translate-x-1/2 px-3 py-1 rounded text-white text-xs"
            style={{
              left: `calc(${completedPercent}%)`,
              backgroundColor: hexColor,
            }}
          >
            {completedPercent}%
            <div
              className="absolute w-0 h-0 border-x-[6px] border-x-transparent border-t-[6px]"
              style={{
                top: "100%",
                left: "50%",
                transform: "translateX(-50%)",
                borderTopColor: hexColor,
              }}
            />
          </div>
        )}
      </div>

      <div
        className="relative w-full rounded-full bg-gray-light mt-4"
        style={{ height: `${height}px` }}
      >
        {/* Expected Progress Bar */}
        {expectedPercent > 0 && (
          <div
            className="absolute h-full rounded-full"
            style={{
              width: `${expectedPercent}%`,
              backgroundImage: `repeating-linear-gradient(135deg, ${hexColor}, ${hexColor} 2px, transparent 2px, transparent 4px)`,
            }}
          />
        )}

        {/* Completed Progress Bar */}
        <div
          className="absolute h-full rounded-full"
          style={{
            width: `${completedPercent}%`,
            backgroundColor: hexColor,
          }}
        />

        {/* Progress Marker */}
        <div
          className="absolute h-[20px] w-[20px] top-0 -translate-x-1/2 -translate-y-1 border-2 border-white rounded-full"
          style={{
            left: `calc(${completedPercent}%)`,
            backgroundColor: hexColor,
          }}
        />
      </div>
      {showDateRange && (
        <div className="mt-2 flex justify-between">
          <p className="font-bold text-typography-65 text-[13px]">January 1</p>
          <p className="font-bold text-typography-65 text-[13px]">
            December 31
          </p>
        </div>
      )}
    </div>
  );
};
