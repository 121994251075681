import { useState } from "react";
import { IMilestone, IStory, StoryStatus } from "@/models";
import { MilestoneService, StoryService } from "@/api/services";
import StoryComponent from "./StoryComponent";

const MilestoneComponent: React.FC<MilestoneComponentProps> = ({
  componentMilestone,
}: MilestoneComponentProps) => {
  const [milestone, setMilestone] = useState<IMilestone>(componentMilestone);
  const [taskTitle, setTaskTitle] = useState<string>("");

  const updateTaskTitle = (input: React.ChangeEvent<HTMLInputElement>) => {
    setTaskTitle(input.target.value);
  };

  const handleStatusChange = async (
    storyId: string,
    newStatus: StoryStatus
  ) => {
    try {
      await StoryService.updateStoryStatus(storyId, newStatus);
      const currentItems: IStory[] = milestone.items ?? [];

      setMilestone({
        ...milestone,
        items: currentItems.map((story) =>
          story.story_id === storyId ? { ...story, status: newStatus } : story
        ),
      });
    } catch (error) {
      console.error("Failed to update story status", error);
    }
  };

  const createNewStory = async () => {
    try {
      const currentItems = milestone.items ?? [];
      const newStory = await MilestoneService.createStory(
        milestone.milestone_id,
        taskTitle
      );
      currentItems.push(newStory);

      setMilestone({
        ...milestone,
        items: currentItems,
      });
      setTaskTitle("");
    } catch {
      console.log(`Failed to add task to the milestone`);
    }
  };

  return (
    <div>
      <h4 style={{ background: "#f0f0f0" }}>{milestone.name}</h4>

      {milestone.items?.length ? (
        milestone.items.map((story) => (
          <StoryComponent
            key={story.story_id}
            story={story}
            onStatusChange={handleStatusChange}
          />
        ))
      ) : (
        <p>No stories yet</p>
      )}

      <div>
        <input
          // TODO: Shouldn't be email. Update to something else
          id="email"
          type="email"
          name="email"
          value={taskTitle}
          onChange={updateTaskTitle}
        />
        <button onClick={createNewStory}>Create</button>
      </div>
    </div>
  );
};

interface MilestoneComponentProps {
  componentMilestone: IMilestone;
}

export default MilestoneComponent;
