import { IInitiative } from "./initiative";

export interface IQuarter {
  quarter_id: number;
  name: QuarterType;
  initiatives: IInitiative[];
  status: QuarterStatus;
}

export enum QuarterType {
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4",
}

export enum QuarterStatus {
  PAST = "PAST",
  PRESENT = "PRESENT",
  FUTURE = "FUTURE",
}
