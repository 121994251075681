import classNames from "classnames";
import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";

interface ModalProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  showCloseButton?: boolean;
  closeOnClickOutside?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  className,
  isOpen,
  onClose,
  children,
  showCloseButton = true,
  closeOnClickOutside = true,
}) => {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsRendered(true);
      document.body.style.overflow = "hidden"; // Disable body scroll
    } else {
      document.body.style.overflow = ""; // Enable body scroll
    }

    return () => {
      document.body.style.overflow = ""; // Ensure cleanup
    };
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setIsRendered(false);
    setTimeout(onClose, 75); // Match this with transition duration
  }, [onClose]);

  const handleClickOutside = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.target === event.currentTarget && closeOnClickOutside) {
        handleClose();
      }
    },
    [closeOnClickOutside, handleClose]
  );

  // Close modal on 'Esc' key press
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleEscapeKey);
    }

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isOpen, handleClose]);

  if (!isOpen && !isRendered) return null;

  return ReactDOM.createPortal(
    <div
      className={classNames(
        "fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-75 bg-black bg-opacity-20",
        isOpen ? "opacity-100" : "opacity-0"
      )}
      onClick={handleClickOutside}
      aria-modal="true"
      role="dialog"
      tabIndex={-1} // Ensure modal is focusable
    >
      <div
        className={classNames(
          "min-w-[620px] bg-white rounded-lg shadow-lg p-8 relative transition-transform duration-75 transform",
          isOpen ? "scale-100" : "scale-95",
          className
        )}
        onClick={(e) => e.stopPropagation()} // Prevent clicks inside modal from closing it
      >
        {showCloseButton && (
          <button
            className="absolute top-8 right-8 text-gray-500 hover:text-gray-700"
            onClick={handleClose}
            aria-label="Close Modal"
          >
            <img
              src="/icons/multiply.svg"
              alt="close modal"
              className="w-5 h-5"
            />
          </button>
        )}

        <div>{children}</div>
      </div>
    </div>,
    document.body
  );
};
